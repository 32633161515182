<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterAll">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="weightsType"
                :options="weightsTypes"
                optionTitle="title"
                :convertValue="true"
                required
                id="performanceFilter_type"
                :label="$t('kpi.weights_type')"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select v-if="weightsType === 1"
                          v-model="filter.product"
                          :options="edonProducts"
                          optionTitle="title"
                          :convertValue="false"
                          required
                          id="performanceFilter_product"
                          :label="$t('rubric.product')"
              >
              </app-select>
              <app-select v-if="weightsType === 2"
                          v-model="filter.department"
                          :options="departments"
                          optionTitle="name"
                          :convertValue="false"
                          required
                          id="performanceFilter_department"
                          :label="$t('menu.departments')"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-date-picker v-if="filter.product || filter.department"
                               v-model="seedDate"
                               :label="$t('kpi.month')"
                               id="month_seed_date"
                               customDateFormat="Z"
                               type="month"
              ></app-date-picker>
            </div>
            <div class="col-lg-2">
              <button
                v-if="filter.product || filter.department"
                class="btn btn-success pull-right m-t-30"
                @click.prevent="filterAll()"
              >{{ $t('beUser.filter.search') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Select from '../form/select/Select'
import WeightsSettingsFilter from '../../model/WeightsSettingsFilter'
import DatePicker from '../form/Datepicker'
import moment from 'moment'

export default {
  name: 'WeightsSettingsFilter',
  data () {
    return {
      weightsType: 0,
      weightsTypes: [
        {
          id: 1,
          title: this.$t('kpi.product')
        },
        {
          id: 2,
          title: this.$t('kpi.department')
        }
      ],
      isLoading: false,
      filter: this._.cloneDeep(WeightsSettingsFilter),
      seedDate: moment().toISOString()
    }
  },
  components: {
    appSelect: Select,
    appDatePicker: DatePicker
  },
  computed: {
    edonProducts () {
      return this.$store.getters['edonProduct/list']
    },
    departments () {
      return this.$store.getters['department/all']
    }
  },
  watch: {
    seedDate: function (seedDate) {
      this.filter.monthYear = moment(seedDate).format('M-YYYY')
    },
    weightsType: function (val) {
      if (val === 0) {
        this.filter.department = 0
        this.filter.product = 0
      }
      if (val === 1) this.filter.department = 0
      if (val === 2) this.filter.product = 0
    }
  },
  methods: {
    getEdonProducts () {
      this.$store.dispatch('edonProduct/fetchAll')
        .then(() => {
          this.isLoading = true
        })
    },
    getDepartments () {
      this.$store.dispatch('department/fetchAll')
        .then(() => {
          this.isLoading = true
        })
    },
    filterAll () {
      this.$store.commit('weights/setFilter', this.filter)
      this.$store.dispatch('weights/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['weights/filter']
  },
  mounted () {
    this.getEdonProducts()
    this.getDepartments()
  }
}
</script>
